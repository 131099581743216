<template>
  <div class="preview-card">
    <MyLink
      class="preview-card__image"
      name="blog_post"
      :params="{
        blogSlug: preview.fields.url.ru,
      }"
    >
      <img
        v-if="preview.fields.img"
        :src="useNormalizeCtfUrl(preview.fields.img.ru.fields.file.ru.url)"
        :alt="getLocalizedName(preview.fields.img.ru.fields.title)"
        loading="lazy"
      />
      <img
        v-else
        src="/img/photo-plug.svg"
        alt="preview image"
        loading="lazy"
      />
    </MyLink>
    <div class="preview-card__content">
      <div class="preview-card__content-header">
        <div class="preview-card__labels">
          <BlogLabel :label="preview.fields.category.ru" />
        </div>
        <time
          v-if="preview.fields.created"
          :datetime="new Date(preview.fields.created.ru).toISOString()"
          class="preview-card__date"
        >
          {{ getDate(preview.fields.created.ru) }}
        </time>
        <!--        <div class="preview-card__views">-->
        <!--          <img-->
        <!--            src="~~/assets/icons/eye-on.svg"-->
        <!--            alt="views"-->
        <!--            class="preview-card__views-image"-->
        <!--          />-->
        <!--          <span class="preview-card__views-count">12234</span>-->
        <!--        </div>-->
      </div>
      <MyLink
        class="preview-card__title"
        name="blog_post"
        :params="{
          blogSlug: preview.fields.url.ru,
        }"
      >
        {{ getLocalizedName(preview.fields.name) }}
      </MyLink>
      <!--      <div class="preview-card__content-footer">-->
      <!--        <div class="preview-card__author">-->
      <!--          <img-->
      <!--            src="/img/photo-plug.svg"-->
      <!--            alt="author photo"-->
      <!--            class="preview-card__author-photo"-->
      <!--          />-->
      <!--          <p class="preview-card__author-name">Author Name</p>-->
      <!--        </div>-->
      <!--        <time-->
      <!--          v-if="preview.fields.created"-->
      <!--          :datetime="new Date(preview.fields.created.ru).toISOString()"-->
      <!--          class="preview-card__date"-->
      <!--        >-->
      <!--          {{ getDate(preview.fields.created.ru) }}-->
      <!--        </time>-->
      <!--        <div class="preview-card__views mobile">-->
      <!--          <img-->
      <!--            src="~~/assets/icons/eye-on.svg"-->
      <!--            alt="views"-->
      <!--            class="preview-card__views-image"-->
      <!--          />-->
      <!--          <span class="preview-card__views-count">12234</span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import BlogLabel from "~/modules/blog/components/BlogLabel.vue";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

//TODO сверстано под новую карточку, закоментированно под старые данные

const props = defineProps({
  preview: { type: Object, required: true },
});

const getDate = (date) =>
  useState("blog card date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(Date.parse(date)),
  );
</script>

<style lang="scss" scoped>
.preview-card {
  max-width: 350px;
  height: 100%;

  @include flex-container(column, flex-start, center);
  gap: 16px;

  background-color: white;
  border-radius: 16px;

  &__image {
    width: 100%;

    & > img {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  &__content {
    height: 100%;

    @include flex-container(column, flex-start);

    padding: 0 16px 16px;
    gap: 16px;

    @include mobile {
      gap: 8px;
      padding: 0 8px 8px;
    }
  }

  &__content-header {
    @include flex-container(row, space-between, center);
    flex-wrap: wrap;
    gap: 8px;
  }

  &__labels {
    @include flex-container(row, flex-start, center);
    flex-wrap: wrap;
    gap: 8px;
  }

  &__views {
    @include flex-container(row, flex-start, center);
    gap: 4px;

    @include mobile {
      display: none;
    }

    &.mobile {
      display: none;

      @include mobile {
        display: flex;
      }
    }
  }

  &__views-image {
    @include fixedHW(24px);
  }

  &__views-count {
    @include font(12, 18, 500);
    color: var(--color-sky-darker);
  }

  &__title {
    display: -webkit-box;

    @include font(18, 24, 600);
    text-wrap: balance;

    transition: color 0.2s;

    &:hover {
      color: var(--color-primary-base);
    }

    @include mobile {
      @include font(12, 18, 500);
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__content-footer {
    @include flex-container(row, space-between, center);
    flex-wrap: wrap;
    gap: 8px;

    margin-top: auto;
  }

  &__author {
    @include flex-container(row, flex-start, center);
    gap: 8px;
  }

  &__author-photo {
    @include fixedHW(30px);

    border-radius: 50%;
  }

  &__author-name {
    @include font(12, 18, 500);
  }

  &__date {
    @include font(14, 20);
    color: var(--color-sky-darker);
  }
}
</style>
